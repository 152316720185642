import { getPublicPropertiesStore } from '../publicMethods/publicProperties/registerPublicProperties';
import { onEventsChange } from '../eventsState/eventsState';
import { PublicPropertiesStore } from '../../shared/types/publicMethods';
import { TagEvent } from '../../shared/types/tagEvent';

export default function trackEvents(
  publicPropertyStore: PublicPropertiesStore = getPublicPropertiesStore(),
) {
  const events: TagEvent[] = [];
  const MAX_EVENTS = 500;

  const eventsChangedCallback = (event: TagEvent) => {
    if (events.length > MAX_EVENTS) {
      events.shift();
    }
    events.push(event);
    publicPropertyStore.dispatch('tag:events', [...events]);
  };

  //_________________________
  onEventsChange(eventsChangedCallback);
}
