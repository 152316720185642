import createState from '../../shared/utils/createState';
import areArraysEqual from '../../shared/utils/areArraysEqual';
import { DisplayedNotification } from '../visitorContextConfig/types';

export const [
  getDisplayedNotifications,
  setDisplayedNotifications,
  onDisplayedNotificationsChange,
] = createState<DisplayedNotification[]>(
  [],
  (r1: DisplayedNotification[], r2: DisplayedNotification[]) =>
    areArraysEqual(
      r1.map((r) => r.engagementRuleId),
      r2.map((r) => r.engagementRuleId),
    ),
);

export const [
  getAllDisplayedNotifications,
  setAllDisplayedNotifications,
  onAllDisplayedNotificationsChange,
] = createState<DisplayedNotification[]>(
  [],
  (r1: DisplayedNotification[], r2: DisplayedNotification[]) =>
    areArraysEqual(
      r1.map((r) => r.engagementRuleId),
      r2.map((r) => r.engagementRuleId),
    ),
);
