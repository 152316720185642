import { getPlatformDomain } from './buildUrl/getPlatformDomain';
import { getLinkQueryParams } from './buildUrl/getScriptLink';
import { IAdvizeInterfaceV2, TagConfig } from './iAdvizeInterface/types';

//______________________________________________________________________________
let tagConfig: TagConfig;

const getTagConfigFromQueryParams = (): TagConfig => {
  const linkQueryParams = getLinkQueryParams();
  const useExplicitCookiesConsent =
    linkQueryParams.get('useExplicitCookiesConsent') === 'true';
  const language = linkQueryParams.get('lang');
  const sid = parseInt(linkQueryParams.get('sid')!, 10);
  const tpl = linkQueryParams.get('tpl');
  return {
    platformDomain: getPlatformDomain(),
    useExplicitCookiesConsent,
    sid,
    ...(language && { language }),
    ...(tpl && { tpl }),
  };
};

export const getTagConfig = (): TagConfig => {
  if (tagConfig) {
    return tagConfig;
  }
  const idzInterfaceTagConfig =
    (window.iAdvizeInterface as IAdvizeInterfaceV2)?.config || {};
  const tagConfigFromQueryParams = getTagConfigFromQueryParams();

  tagConfig = {
    ...tagConfigFromQueryParams,
    ...idzInterfaceTagConfig,
  };

  //___________________________________________________
  if (typeof tagConfig.sid === 'string' && !!tagConfig.sid) {
    tagConfig.sid = parseInt(tagConfig.sid, 10);
  }

  return tagConfig;
};

export const getLanguage = () => getTagConfig().language;

//__________________________
export const resetTagConfig = () => {
  //________________________________
  tagConfig = undefined;
};
