import CustomEventEmitter, { Dispatcher } from './CustomEventEmitter';

type CustomEventsFactory<CustomEvents extends Record<string, unknown>> = {
  [Key in keyof CustomEvents]: (value: CustomEvents[Key]) => void;
};

export type StoreEventsFactory<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
> = CustomEventsFactory<CustomEventsKeys> & {
  [Key in keyof State]: (value: State[Key]) => void;
};

type DispatcherWithKeys<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
> = Dispatcher<StoreEventsFactory<State, CustomEventsKeys>>;

export type Store<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
> = {
  dispatch: DispatcherWithKeys<State, CustomEventsKeys>['emit'];
  updateState: (key: keyof State, value: State[keyof State]) => void;
  isSameValue: (
    currentValue: State[keyof State],
    newValue: State[keyof State],
  ) => boolean;
  getState: () => State;
  off: DispatcherWithKeys<State, CustomEventsKeys>['off'];
  on: DispatcherWithKeys<State, CustomEventsKeys>['on'];
  onAny: DispatcherWithKeys<State, CustomEventsKeys>['onAny'];
  once: DispatcherWithKeys<State, CustomEventsKeys>['once'];
  emit: DispatcherWithKeys<State, CustomEventsKeys>['emit'];
  reset: () => void;
  cleanAllListeners: () => void;
};

function createStore<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
>(initialState: State, namespace = 'store'): Store<State, CustomEventsKeys> {
  let state = { ...initialState };
  type StoreEvents = StoreEventsFactory<State, CustomEventsKeys>;
  const eventEmitter = CustomEventEmitter<StoreEvents>(namespace);

  const updateState = (key: keyof State, value: State[keyof State]) => {
    state[key] = value;
  };

  const reset = () => {
    state = { ...initialState };
  };

  const isSameValue = (
    currentValue: State[keyof State],
    newValue: State[keyof State],
  ) => {
    return (
      currentValue !== undefined &&
      currentValue !== null &&
      currentValue === newValue
    );
  };

  const dispatch: typeof eventEmitter.emit = (type, ...details) => {
    const currentValue = (state as State)[type as keyof State];
    const [value] = details;

    if (isSameValue(currentValue, value as State[keyof State])) {
      return;
    }

    updateState(type as keyof State, value as State[keyof State]);
    eventEmitter.emit(type, ...details);
  };

  return {
    dispatch,
    updateState,
    isSameValue,
    getState: () => state,
    off: eventEmitter.off,
    on: eventEmitter.on,
    onAny: eventEmitter.onAny,
    once: eventEmitter.once,
    emit: eventEmitter.emit,
    reset,
    cleanAllListeners: eventEmitter.cleanAllListeners,
  };
}

export default createStore;
