/*____________________________________________________________________________________________________________________________________________________________*/
import getTopDomain from './getTopDomain';
import { setCookie } from './setCookie';
import loggerServiceFactory from '../../livechat/src/loggerService/loggerServiceFactory';
import { getEncodedCookieValue } from './utils';

export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length > 1) {
    const unencodedCookieValue = parts[1].split(';')[0] || null;

    if (!unencodedCookieValue) {
      return null;
    }

    const isFirstPartyStorage = !window.location.href.includes('storage.php');
    const loggerService = loggerServiceFactory(
      isFirstPartyStorage ? window.iAdvize?.chaturl : '/',
      window.iAdvize?.website_id,
    );

    let cookieValue = unencodedCookieValue;

    if (unencodedCookieValue.includes('{')) {
      cookieValue = getEncodedCookieValue(unencodedCookieValue);
      setCookie({
        name,
        value: cookieValue,
        domain: isFirstPartyStorage ? getTopDomain() : undefined,
      });
      loggerService.info(
        'cookieStorage.error',
        `found cookie with unencoded data : ${name}`,
      );
    }

    return cookieValue;
  }
  return null;
};
