import { publicGettableProperties } from '.';
import {
  PublicGettableProperty,
  PublicMethodsCallbacks,
  PublicPropertiesStore,
  PublicPropertiesState,
} from '../../../shared/types/publicMethods';
import createStore, {
  StoreEventsFactory,
} from '../../../shared/utils/createStore';
import { registerGetter } from '../get/get';

const publicPropertiesState: PublicPropertiesState = {
  'app:boundaries': {
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  },
  'chatbox:status': 'CLOSED',
  'conversation:id': undefined,
  'tag:version': 'FULL',
  'tag:events': [],
  'visitor:cookiesConsent': null,
  'visitor:GDPRConsent': null,
  'visitor:sourceId': null,
  'engagementRule:triggered': null,
  'engagementRules:triggered': [],
  'engagementNotification:displayed': null,
  'engagementNotifications:displayed': [],
  'engagementNotification:clicked': null,
  'engagementNotifications:controlled': [],
  'engagementNotification:controlled': null,
};

let publicPropertiesStore = {} as PublicPropertiesStore;

export const registerGettablePublicProperties = () => {
  publicGettableProperties.forEach((property: PublicGettableProperty) =>
    registerGetter(
      property,
      (() =>
        publicPropertiesStore.getState()[
          property
        ]) as PublicMethodsCallbacks[typeof property],
    ),
  );
};

const registerPublicProperties = () => {
  publicPropertiesStore = createStore(publicPropertiesState, 'store');

  const { emit, isSameValue, getState } = publicPropertiesStore;
  const wrappedDispatch: typeof publicPropertiesStore.dispatch = (
    type,
    ...details
  ) => {
    const currentValue = getState()[type];
    const [value] = details;

    if (isSameValue(currentValue, value)) {
      return;
    }

    publicPropertiesStore.updateState(type, value);
    //_______________________________________________________________
    if (currentValue !== undefined) {
      //____________________________________________________
      const detailsWithPreviousValue = [...details, currentValue];
      emit(
        type,
        ...(detailsWithPreviousValue as Parameters<
          StoreEventsFactory<PublicPropertiesState>[typeof type]
        >),
      );
    }
  };

  publicPropertiesStore.dispatch = wrappedDispatch;
};

export const getPublicPropertiesStore = () => publicPropertiesStore;

export default registerPublicProperties;
