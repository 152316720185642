export default function createState<T>(
  initialValue: T,
  comparisonFunction = (a: T, b: T) => a === b,
): readonly [
  getValue: () => T,
  setValue: (newValue: T) => void,
  onValueChange: ((callback: (newValue: T, oldValue: T) => void) => void) & {
    clear: (callback: (newValue: T, oldValue: T) => void) => void;
  },
  resetValue: () => void,
  onValueReset: ((callback: (newValue: T, oldValue: T) => void) => void) & {
    clear: (callback: (newValue: T, oldValue: T) => void) => void;
  },
] {
  let value = initialValue;
  const onChangeCallbacks: Set<(newValue: T, oldValue: T) => void> = new Set();
  const onResetCallbacks: Set<(newValue: T, oldValue: T) => void> = new Set();

  const getValue = (): T => value;

  const setValue = (newValue: T): void => {
    if (comparisonFunction(newValue, value)) return;
    const oldValue = value;
    value = newValue;
    onChangeCallbacks.forEach((callback) => callback(newValue, oldValue));
  };

  const resetValue = (): void => {
    const oldValue = value;
    const newValue = initialValue;
    value = initialValue;
    onResetCallbacks.forEach((callback) => callback(newValue, oldValue));
  };

  const onValueReset = (callback: (newValue: T, oldValue: T) => void): void => {
    onResetCallbacks.add(callback);
  };

  onValueReset.clear = (callback: (newValue: T, oldValue: T) => void): void => {
    onResetCallbacks.delete(callback);
  };

  const onValueChange = (
    callback: (newValue: T, oldValue: T) => void,
  ): void => {
    onChangeCallbacks.add(callback);
  };

  onValueChange.clear = (
    callback: (newValue: T, oldValue: T) => void,
  ): void => {
    onChangeCallbacks.delete(callback);
  };

  return [getValue, setValue, onValueChange, resetValue, onValueReset] as const;
}
