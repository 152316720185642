import createState from '../../shared/utils/createState';
import { getTagConfig } from '../tagConfig';

export const [getIsGdprConsentEnabled, setIsGdprConsentEnabled] =
  createState(false);

export const [
  getIsImplicitGdprConsentEnabled,
  setIsImplicitGdprConsentEnabled,
] = createState(false);

export const [
  getUseExplicitCookiesConsentDeprecated,
  setUseExplicitCookiesConsentDeprecated,
] = createState(false);

export const [getIsCmpAppConfigured, setIsCmpAppConfigured] =
  createState(false);

/*_________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const getUseExplicitCookiesConsent = () => {
  const isExplicitCookiesConsentEnabled = () => {
    const isGdprConsentEnabled =
      getIsGdprConsentEnabled() || getIsCmpAppConfigured();
    if (!getUseExplicitCookiesConsentDeprecated()) {
      return getTagConfig().useExplicitCookiesConsent || isGdprConsentEnabled;
    }
    return isGdprConsentEnabled;
  };

  return (
    !getIsImplicitGdprConsentEnabled() && isExplicitCookiesConsentEnabled()
  );
};
